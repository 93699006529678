import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { gridMap } from '../../utils'
import { FlexCard, CardTextBlock } from '../Card'
import { AssetLink } from './../EntryLink'
import { AspectImage } from '../Image'

const MediaLibraryEntry = (props) => {
  const { image, imageDescription } = props
  const { t } = useTranslation()

  return (
    <FlexCard
      noSpacing={true}
      media={image && <AspectImage {...image.asset} />}
      width={gridMap['one-third']}
      action={
        image?.asset && (
          <AssetLink {...image} linkText={t('GENERAL-DOWNLOAD')} />
        )
      }
    >
      <CardTextBlock text={imageDescription} />
    </FlexCard>
  )
}

export const query = graphql`
  fragment MediaLibraryEntry on contentful_MediaLibraryEntry {
    __typename
    sys {
      id
    }
    image {
      ...AssetLink
    }
    imageDescription
  }
`

export default MediaLibraryEntry
